// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Importiere deine Seitenkomponenten
import Home from './screens/Home';
import Impressum from './screens/Impressum';
import Login from './screens/Login';
import { AuthProvider } from './contexts/authContext';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/impressum" element={<Impressum />} />
          {/* Geschützte Routen */}
          <Route path="/" element={<ProtectedRoute element={<Home />} />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
