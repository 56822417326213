// functions for communication with the TP-Link Kasa API
import { KASA_DEVICE_ID, KASA_TOKEN, KASA_APP_SERVER_URL } from '../config/config';

export const toggleDeviceState = async (checked) => {
    const payload = {
        method: 'passthrough',
        params: {
            deviceId: KASA_DEVICE_ID,
            requestData: JSON.stringify({
                system: {
                    set_relay_state: {
                        state: checked ? 1 : 0
                    }
                }
            })
        }
    };

    const response = await fetch(`${KASA_APP_SERVER_URL}?token=${KASA_TOKEN}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
    });

    if (!response.ok) {
        throw new Error('Failed to send command to TP-Link Kasa');
    }

    const result = await response.json();
    if (result.error_code !== 0) {
        throw new Error(`Error from TP-Link Kasa: ${result.error_code}`);
    }

    return result;
};
