// screens/Login.js
import React from 'react';
import { useAuth } from '../contexts/authContext';
import { useNavigate } from 'react-router-dom';  // Importiere useNavigate Hook


const Login = () => {
    const { login } = useAuth();
    const navigate = useNavigate();  // Initialisiere den useNavigate Hook

    const handleLogin = () => {
        // Hier könntest du auch eine API-Anfrage einfügen, um die Anmeldeinformationen zu überprüfen
        login(); // Ruf die login-Methode auf, um den Benutzer zu authentifizieren
        navigate('/');
    };

    return (
        <div className="">
            <section className="">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                    <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900">
                        <img className="w-8 h-8 mr-2" src="./img/android-chrome-192x192.png" alt="logo" />
                        smarthomelucas
                    </a>
                    <div className="w-full bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 shadow-lg shadow-lime-500/50 rounded-lg md:mt-0 sm:max-w-md xl:p-0">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                Login
                            </h1>
                            <div className="space-y-4 md:space-y-6">
                                <div>
                                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">E-Mail</label>
                                    <input type="email" name="email" id="email" className="bg-gray-100 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="name@company.com" required="" />
                                </div>
                                <div>
                                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Passwort</label>
                                    <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-100 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required="" />
                                </div>
                                <div className="flex flex-col sm:flex-row sm:justify-between">
                                    <div className="flex items-start">
                                        <div className="flex items-center h-5">
                                            <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300" required="" />
                                        </div>
                                        <div className="ml-3 text-sm font-medium">
                                            <label htmlFor="remember" className="text-gray-900">Login-Infos speichern</label>
                                        </div>
                                    </div>
                                    <div className="flex mt-4 sm:mt-0 text-sm font-medium text-gray-900 hover:underline dark:text-primary-500">Passwort vergessen?</div>
                                </div>
                                <div className='flex justify-center items-center'>
                                    <button onClick={handleLogin} className="w-1/3 text-gray-100 bg-gray-900 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign in</button>
                                </div>
                                <div className='flex justify-center sm:justify-start'>
                                    <p className="text-sm font-light text-gray-900">
                                        Noch keinen Account? <a href="#" className="font-medium text-gray-900 hover:underline">Jetzt registrieren</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="bg-transparent rounded-lg mt-8">
                <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between text-center">
                    <span className="text-sm text-gray-500 sm:text-center">© 2024 <a href="https://smarthomelucas.sytes.net/" className="hover:underline">smarthomelucas</a>. All Rights Reserved.
                    </span>
                    <div className="flex items-center justify-center mt-3 text-sm font-medium text-gray-500 sm:mt-0">
                        <div>
                            <a href="./impressum" className="hover:underline me-4 md:me-6">Impressum</a>
                        </div>
                        <div>
                            <a href="./privacy-policy" className="hover:underline me-4 md:me-6">Privacy Policy</a>
                        </div>
                        <div>
                            <a href="#" className="hover:underline me-4 md:me-6">Licensing</a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Login;
