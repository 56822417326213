// Component for Temperature Card
import React from 'react';

const TemperatureCard = ({ temperature }) => (
    <div className="w-full sm:w-1/3 max-w-sm md:w-full sm:h-1/3 p-5 lg:h-fit">
        <div className="flex flex-col justify-between bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 shadow-lg shadow-blue-500/50 p-8 rounded-2xl">
            <div className="flex items-center mb-3">
                <div
                    className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 dark:bg-gray-900 text-white flex-shrink-0">
                    <svg fill="#ffffff" viewBox="-3.2 -3.2 38.40 38.40" width={25} version="1.1" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsserif="http://www.serif.com/" xmlnsXlink="http://www.w3.org/1999/xlink" stroke="#ffffff" strokeWidth="0.00032"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M11,30.079c0,0 -0.022,0 -0.03,0c-2.746,-0.016 -4.97,-2.25 -4.97,-5c-0,-1.629 0.78,-3.077 1.988,-3.99l-0,-16.07c-0,-1.66 1.347,-3.007 3.006,-3.007c1.659,0 3.006,1.347 3.006,3.007c-0,-0 -0,16.061 -0,16.061c1.214,0.912 2,2.365 2,3.999c0,2.717 -2.171,4.93 -4.871,4.999l-0.129,0.001m0,-2c1.656,0 3,-1.344 3,-3c0,-1.11 -0.604,-2.079 -1.501,-2.598c-0.309,-0.179 -0.499,-0.509 -0.499,-0.866l0,-16.596c0,-0.556 -0.451,-1.007 -1.006,-1.007c-0.556,0 -1.006,0.451 -1.006,1.007l-0,16.603c-0,0.356 -0.189,0.685 -0.496,0.864c-0.892,0.52 -1.492,1.487 -1.492,2.593c0,1.65 1.335,2.991 2.983,3c0.001,0 0.003,0 0.004,0"></path><path d="M17.006,8l7.994,0.001c0.552,0 1,-0.448 1,-1c0,-0.552 -0.448,-1 -1,-1l-7.993,-0.001c-0.552,-0 -1,0.448 -1.001,1c0,0.552 0.448,1 1,1Z"></path><path d="M17.006,11.987l6.217,0.001c0.552,-0 1.001,-0.448 1.001,-1c-0,-0.552 -0.448,-1 -1,-1l-6.217,-0.001c-0.552,-0 -1,0.448 -1.001,0.999c0,0.552 0.448,1.001 1,1.001Z"></path><path d="M17.006,15.973l7.994,0.001c0.552,0 1,-0.448 1,-1c0,-0.552 -0.448,-1 -1,-1l-7.993,-0.001c-0.552,0 -1,0.448 -1.001,1c0,0.552 0.448,1 1,1Z"></path>
                        <path d="M17.006,19.96l6.217,0.001c0.552,-0 1.001,-0.448 1.001,-1c-0,-0.552 -0.448,-1 -1,-1l-6.217,-0.001c-0.552,-0 -1,0.448 -1.001,1c0,0.552 0.448,1 1,1Z"></path><circle cx="11.001" cy="25.008" r="1"></circle></g></svg>
                </div>
                <h2 className="text-lg font-medium text-gray-100">Temperatur</h2>
                <div
                    className="w-8 h-8 ml-12 inline-flex items-center justify-center ">
                </div>
            </div>
            <div className="flex-grow">
                <p className="font-bold text-5xl text-gray-100 text-center mt-10">
                    {temperature}°C
                </p>
                <p className="font-semibold text-sm text-gray-100 text-center mt-2">
                    Temperatur
                </p>
                <div className='flex justify-center'>
                    <button className="mt-10 text-gray-100 bg-gray-900 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                        Verlauf
                    </button>
                </div>
            </div>
        </div>
    </div>
);

export default TemperatureCard;