import React, { useState, useEffect, useRef } from 'react';
import { toggleDeviceState } from '../utils/kasaApi';
import { fetchHumidity, fetchDeviceStatus } from '../utils/fetchData';
import HumidityCard from '../components/HumidityCard';
import TemperatureCard from '../components/TemperatureCard';
import DeviceStatus from '../components/DeviceStatus';
import { FaHouse, FaUser, FaAnglesLeft } from 'react-icons/fa6';


function Home() {
    const [humidity, setHumidity] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const temperature = 21.5; // static value // todo -> implement temperature value
    const wsRef = useRef(null); // Referenz für die WebSocket-Verbindung
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    }


    // switch toggle depending on device status
    const handleToggle = async (e) => {
        const checked = e.target.checked;
        setIsChecked(checked);
        try {
            await toggleDeviceState(checked);
        } catch (error) {
            console.error('Fehler beim Umschalten:', error);
        }
    };

    // Funktion zum Aufbau der WebSocket-Verbindung
    const connectWebSocket = () => {
        // Überprüfen, ob bereits eine Verbindung besteht
        if (wsRef.current && wsRef.current.readyState !== WebSocket.CLOSED) {
            console.log('WebSocket-Verbindung bereits hergestellt oder wird gerade hergestellt.');
            return;
        }

        wsRef.current = new WebSocket('wss://smarthomelucas.sytes.net/api');

        wsRef.current.onopen = () => {
            console.log('WebSocket verbunden');
        };

        wsRef.current.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.type === 'humidity') {
                setHumidity(message.humidity);
            } else if (message.type === 'device-status') {
                setIsChecked(message.isOn);
            }
        };

        wsRef.current.onclose = () => {
            console.log('WebSocket geschlossen. Versuche erneut zu verbinden...');
            connectWebSocket();
        };

        wsRef.current.onerror = (error) => {
            console.error('WebSocket-Fehler:', error);
        };
    };

    // WebSocket-Verbindung beim Laden der Komponente herstellen
    useEffect(() => {
        connectWebSocket();

        // Event-Listener für das "focus"-Event hinzufügen, um die Verbindung erneut herzustellen
        const handleFocus = () => {
            console.log('Fenster fokussiert.');
            connectWebSocket();
        };

        window.addEventListener('focus', handleFocus);

        // Aufräumen beim Verlassen der Seite
        return () => {
            window.removeEventListener('focus', handleFocus);
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, []);

    return (

        <div className="flex min-h-screen">
            {/* Side Navigation */}
            <div
                className={`max-h-screen rounded-xl transition-all duration-300 bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 shadow-lg shadow-blue-500/50 text-white ${isSidebarOpen ? 'w-64' : 'w-20'} flex flex-col m-3`}
            >
                {/* Logo and Toggle */}
                <div className="flex items-center justify-between p-4">
                    <div className="flex items-center">
                        <div className="h-10 w-10 rounded-full bg-white flex items-center justify-center">
                            <span className="text-blue-600 font-bold">S</span>
                        </div>
                        {isSidebarOpen && <span className="ml-2 text-xl font-semibold">smarthomelucas</span>}
                    </div>
                    <button onClick={toggleSidebar} className="text-white focus:outline-none">
                        {!isSidebarOpen ? <FaAnglesLeft /> : 'X'}
                    </button>
                </div>

                {/* Search */}
                {isSidebarOpen && (
                    <div className="p-4">
                        <input
                            type="text"
                            placeholder="Quick Search..."
                            className="w-full p-2 rounded-full bg-blue-500 placeholder-white focus:outline-none"
                        />
                    </div>
                )}

                {/* Navigation Links */}
                <nav className={`flex p-4 ${!isSidebarOpen ? 'items-center justify-center' : 'items-start justify-start'}`}>
                    <ul className="space-y-2">
                        <li className="group flex items-center p-2 hover:bg-blue-700">
                            <FaHouse />
                            {isSidebarOpen && <span className="ml-3">Dashboard</span>}
                            {!isSidebarOpen && (
                                <span className="absolute ml-14 opacity-0 group-hover:opacity-100 bg-blue-700 text-white text-xs rounded px-2 py-1">Dashboard</span>
                            )}
                        </li>
                        {/* ...weitere Links... */}
                    </ul>
                </nav>

                {/* Shortcuts */}
                <div className={`flex p-4 mt-auto ${!isSidebarOpen ? 'items-center justify-center' : 'items-start justify-start'}`}>
                    <ul className="space-y-2">
                        {/* ...weitere Shortcuts... */}
                    </ul>
                </div>

                {/* User Profile */}
                <div className={`flex p-4 space-x-2 ${!isSidebarOpen ? 'items-center' : 'items-start'}`}>
                    <div className="h-10 w-10 rounded-full bg-white flex items-center justify-center">
                        <FaUser className="text-blue-600" />
                    </div>
                    {isSidebarOpen && (
                        <div>
                            <p className="text-sm">John Doe</p>
                            <p className="text-xs">Admin</p>
                        </div>
                    )}
                </div>
            </div>

            {/* Main Content */}
            <div className="flex-1 flex flex-col p-4">
                <div className="flex flex-col lg:flex-row items-center justify-around flex-1 lg:h-full">
                    {/* Cards werden hier eingefügt */}
                    <HumidityCard humidity={humidity} isChecked={isChecked} handleToggle={handleToggle} />
                    <TemperatureCard temperature={temperature} />
                    <DeviceStatus isChecked={isChecked} />
                </div>

                {/* Footer */}
                <footer className="bg-transparent rounded-lg mt-4">
                    <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between text-center">
                        <span className="text-sm text-gray-500 sm:text-center">
                            © 2024{' '}
                            <a href="https://smarthomelucas.sytes.net/" className="hover:underline">
                                smarthomelucas
                            </a>
                            . All Rights Reserved.
                        </span>
                        <div className="flex items-center justify-center mt-3 text-sm font-medium text-gray-500 sm:mt-0">
                            {/* ...weitere Footer-Links... */}
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default Home;
