// Component for DeviceStatus Card
import React from 'react';

const DeviceStatus = ({ isChecked }) => (
    <div className="w-full sm:w-1/3 max-w-sm md:w-full sm:h-1/2 p-5 lg:h-fit">
        <div className="flex flex-col justify-between h-full bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 shadow-lg shadow-purple-500/50 p-8 rounded-2xl">

            <div className="flex items-center mb-5">
                <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 dark:bg-gray-900 text-white flex-shrink-0">
                    <svg viewBox="0 0 24 24" width={24} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_iconCarrier">
                            <path id="Vector" d="M4 17H3.5C2.67157 17 2 17.6716 2 18.5C2 19.3284 2.67157 20 3.5 20H20.5C21.3284 20 22 19.3284 22 18.5C22 17.6716 21.3284 17 20.5 17H20M4 17H20M4 17V8.2002C4 7.08009 4 6.51962 4.21799 6.0918C4.40973 5.71547 4.71547 5.40973 5.0918 5.21799C5.51962 5 6.08009 5 7.2002 5H16.8002C17.9203 5 18.4796 5 18.9074 5.21799C19.2837 5.40973 19.5905 5.71547 19.7822 6.0918C20 6.5192 20 7.07899 20 8.19691V17" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </g>
                    </svg>
                </div>
                <h2 className="text-lg font-medium text-gray-100">Geräte</h2>
            </div>
            <div className="flex items-center mt-5 mb-10">
                <span className="relative flex h-3 w-3 mr-3">
                    <span className={`absolute inline-flex h-full w-full rounded-full ${isChecked ? 'animate-ping bg-green-400 opacity-75' : 'bg-gray-900'
                        }`}
                    />
                    <span className={`relative inline-flex rounded-full h-3 w-3 ${isChecked ? 'bg-green-500' : 'bg-gray-900'
                        }`}
                    />
                </span>
                <div className="font-semibold text-sm text-gray-100">Luftentfeuchter</div>
            </div>
            <div className='flex p-3.5'></div>
            <div className='flex justify-center'>
                <button className="mt-10 text-gray-100 bg-gray-900 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                    Alle stoppen
                </button>
            </div>

        </div>
    </div>
);

export default DeviceStatus;
