import React from 'react';
function Impressum() {


    return (
        <div className='flex-wrap space-y-5 space-x-5 m-10'>

            <div className='text-3xl font-bold ml-5'>Impressum</div>

            <div>
                Informationspflicht laut §5 E-Commerce Gesetz und Offenlegungspflicht laut §25 Mediengesetz
            </div>
            <div className='text-xl font-semibold'>Für den Inhalt verantwortlich</div>
            <div>
                smarthomelucas
            </div>
            <div>
                Natürliche (Privat-)Person
            </div>
            <div>
                Österreich
            </div>
            <div>
                E-Mail: <a href="mailto:smarthomelucas%40byom.de">smarthomelucas@byom.de</a>
            </div>


            <div className='text-xl font-semibold'>
                Copyright &amp; Urheberrechtshinweis
            </div>
            <div>
                Alle Inhalte dieser Webpräsenz, insbesondere Texte, Fotografien und Grafiken, sind urheberrechtlich geschützt und teilweise lizensiert. Das Urheberrecht liegt, soweit nicht ausdrücklich anders gekennzeichnet, bei der im Impressum angeführten natürlichen bzw. juristischen Person. Bitte fragen Sie uns, falls Sie die Inhalte dieses Internetangebotes in irgendeiner Art und Weise verwenden möchten.
                Unter der „Creative Commons“-Lizenz“ veröffentlichte Inhalte, sind als solche gekennzeichnet. Sie dürfen entsprechend den angegebenen Lizenzbedingungen verwendet werden.  Wer gegen das Urheberrecht verstößt (z.B. Bilder oder Texte unerlaubt kopiert), macht sich unter Umständen gem. UrhG strafbar.
            </div>



            <div className='text-xl font-semibold'>
                Haftungsausschluss für ausgehende Links
            </div>
            <div>
                Diese Webpräsenz enthält, so wie viele andere Webseiten ebenfalls, so genannte Links auf andere, fremde Webpräsenzen und Inhalte. Das wird als Urgedanke des Internets und des weltweiten Informationsaustauschs als gang und gäbe angesehen. Rechtlich und technisch gesehen haben wir als Betreiber unserer Webseite keinen Einfluss auf die Gestaltung sowie den Inhalt der verlinkten Webpräsenzen. Aus diesem Grund können wir zu keinem Zeitpunkt für diese Webpräsenzen in irgendeiner Art und Weise Haftung oder Gewähr übernehmen, da diese im Verantwortungsbereich des jeweiligen Betreibers angesiedelt ist.

                Zum Zeitpunkt der Verlinkung wurden die fremden Inhalte auf Funktionalität, rechtswidrige Inhalte sowie uns möglich auf Schadsoftware überprüft. Als Indiz finden Sie bei einigen Links einen Datumsstempel der auf den Zeitpunkt der gesetzten Verlinkung hinweist. Nichts desto trotz sind wir bemüht unsere Besucher vor rechtswidrigen Inhalten und Schadsoftware zu schützen weshalb wir stichprobenartig die gesetzten Links in wiederkehrenden Intervallen untersuchen. Bei etwaigen Rechtsverletzungen werden die gesetzten Links selbstverständlich umgehend entfernt. Sollten Sie einen derartigen Link vor unserer Überprüfung gefunden haben, so bitten wir um umgehende Benachrichtigung an die im Impressum angezeigten Kontaktdaten. Bitte übermitteln Sie uns dabei den Link unserer Webseite die den fragwürdigen Verweis enthält, sowie die Art der Kennzeichnung (a) rechtwidriger Inhalt, b) Schadsoftware oder c) inaktiver Inhalt bzw. Verweis).
            </div>




            <div className='text-xl font-semibold'>
                Ihre Rechte
            </div>
            <div>
                Ihnen stehen bezüglich Ihrer bei uns gespeicherten Daten grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der oben genannten E-Mail-Adresse oder Ihrer Datenschutzbehörde beschweren.
            </div>
        </div>

    );
}

export default Impressum;